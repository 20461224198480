import React from 'react';
import { Masthead } from 'patterns/Masthead';
import { Newsletter } from 'patterns/Newsletter';
import { NewsletterList } from 'patterns/NewsletterList';
import { Shell } from 'patterns/Shell';

import '../scss/main.scss';

function NewsletterPage({
    location: {
        pathname,
    },
}) {
    const data = {
        masthead: {
            title: 'Freelance insights',
            description: [
                {
                    spans: [],
                    text: 'Somewhat rambly, but I’ve been told how valuable it is to some and has helped people start their own freelance journey.',
                    type: 'paragraph',
                },
            ],
        },
        newsletter: {
            items: [
                {
                    card_books: 1,
                    card_date: 'November 2022',
                    card_description: 'Reflection, auditing and taking control',
                    card_goals: 1,
                    card_income: 9,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-14',
                    },
                    card_title: {
                        text: 'Issue #14',
                    },
                },
                {
                    card_books: 0,
                    card_date: 'October 2022',
                    card_description: 'Business update, freelance advice and more',
                    card_goals: 2,
                    card_income: 4.4,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-13',
                    },
                    card_title: {
                        text: 'Issue #13',
                    },
                },
                {
                    card_books: 1,
                    card_date: 'May 2022',
                    card_description: 'New business plans, paid products on the horizon and more',
                    card_goals: 2,
                    card_income: 8.7,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-12',
                    },
                    card_title: {
                        text: 'Issue #12',
                    },
                },
                {
                    card_books: 1,
                    card_date: 'March 2022',
                    card_description: 'New client, office makeover, charity donations and more',
                    card_goals: 2,
                    card_income: 7,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-11',
                    },
                    card_title: {
                        text: 'Issue #11',
                    },
                },
                {
                    card_books: 2,
                    card_date: 'February 2022',
                    card_description: 'Second child, investments, new projects and more',
                    card_goals: 2,
                    card_income: 3.1,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-10',
                    },
                    card_title: {
                        text: 'Issue #10',
                    },
                },
                {
                    card_books: 3,
                    card_date: 'January 2022',
                    card_description: 'Launched side project, walking habit, tracking my finances and more',
                    card_goals: 3,
                    card_income: 6.8,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-9',
                    },
                    card_title: {
                        text: 'Issue #9',
                    },
                },
                {
                    card_books: 1,
                    card_date: 'October 2021',
                    card_description: 'Contracting, tax return, eco website metrics and more',
                    card_goals: 2,
                    card_income: 5,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-8',
                    },
                    card_title: {
                        text: 'Issue #8',
                    },
                },
                {
                    card_books: 1,
                    card_date: 'June 2021',
                    card_description: 'New tech, focusing on my health, tree planting and more',
                    card_goals: 2,
                    card_income: 6.1,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-7',
                    },
                    card_title: {
                        text: 'Issue #7',
                    },
                },
                {
                    card_books: 1,
                    card_date: 'May 2021',
                    card_description: 'My first freelancing failure...',
                    card_goals: 1,
                    card_income: 7.9,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-6',
                    },
                    card_title: {
                        text: 'Issue #6',
                    },
                },
                {
                    card_books: 1,
                    card_date: 'April 2021',
                    card_description: 'Freelancing, health, pensions and more',
                    card_goals: 0,
                    card_income: 2.4,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-5',
                    },
                    card_title: {
                        text: 'Issue #5',
                    },
                },
                {
                    card_books: 1,
                    card_date: 'March 2021',
                    card_description: 'Website progress, leads for Jamstack devs and more',
                    card_goals: 1,
                    card_income: 8.8,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-4',
                    },
                    card_title: {
                        text: 'Issue #4',
                    },
                },
                {
                    card_books: 1,
                    card_date: 'February 2021',
                    card_description: 'New social plan, website tech and more',
                    card_goals: 2,
                    card_income: 6.9,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-3',
                    },
                    card_title: {
                        text: 'Issue #3',
                    },
                },
                {
                    card_books: 1,
                    card_date: 'January 2021',
                    card_description: 'Freelancing, finances, tech and more',
                    card_goals: 1,
                    card_income: 7.5,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-2',
                    },
                    card_title: {
                        text: 'Issue #2',
                    },
                },
                {
                    card_books: 1,
                    card_date: 'December 2020',
                    card_description: 'Updated newsletter about my freelance journey',
                    card_goals: 0,
                    card_income: 6.2,
                    card_link_url: {
                        link_type: 'Document',
                        uid: 'newsletter/issue-1',
                    },
                    card_title: {
                        text: 'Issue #1',
                    },
                },
            ],
        }
    };

    return (
        <Shell
            pageDescription={data.masthead.description[0].text}
            pageSocialImage=""
            pageTitle="Newsletter"
            pathname={pathname}
        >
            <Masthead
                description={data.masthead.description}
                title={data.masthead.title}
            />
            <Newsletter />
            <NewsletterList items={data.newsletter.items} />
        </Shell>
    );
}

export default NewsletterPage;
