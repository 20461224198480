import React from 'react';

import { cx } from 'utility';
import { Text } from '../Text';
import * as styles from './Label.module.scss';

function getClasses({ className }) {
    const classes = cx(
        className,
        styles['label'],
    );

    return classes || undefined;
}

function Label({
    children,
    className,
    id,
    required,
    ...props
}) {
    const classes = getClasses({ className });

    return (
        <Text as="label" className={classes} emphasis="medium" htmlFor={id} size={2} {...props}>
            {children}
            {!required && (
                <Text as="span" className={styles['optional']} emphasis="low">(optional)</Text>
            )}
        </Text>
    );
}

export { Label };
