import React from 'react';
import PropTypes from 'prop-types';

import { cx } from 'utility';
import { Label } from '../Label';
import { Text } from '../Text';
import * as styles from './Input.module.scss';

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onSurface: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
};

const defaultProps = {
    className: '',
    disabled: false,
    id: '',
    label: '',
    name: '',
    onSurface: false,
    placeholder: '',
    required: false,
    type: 'text',
};

function getClasses({ className, onSurface }) {
    const classes = cx(
        className,
        styles['input'],
        onSurface && styles['inputOnSurface'],
    );

    return classes || undefined;
}

function Input({
    className,
    disabled,
    id,
    label,
    name,
    onChange,
    onSurface,
    placeholder,
    required,
    type,
}) {
    const classes = getClasses({ className, onSurface });

    return (
        <div className={classes}>
            {label && (
                <Label id={id} required={required}>{label}</Label>
            )}
            <Text as="input" className={styles['field']} disabled={disabled} emphasis="low" id={id} name={name} onChange={onChange} placeholder={placeholder} required={required} size={1} type={type} />
        </div>
    );
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export { Input };
