import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';

import { Grid } from 'components/Grid';
import { Heading, HeadingBoundary } from 'components/Heading';
import { Link } from 'components/Link';
import { Text } from 'components/Text';
import { cx } from 'utility';
import * as styles from './NewsletterList.module.scss';

const propTypes = {
    background: PropTypes.bool,
    className: PropTypes.string,
    items: PropTypes.array,
};

const defaultProps = {
    background: false,
    className: '',
    items: [],
};

function getClasses({ className, background }) {
    const classes = cx(
        className,
        styles['NewsletterList'],
        background && styles['NewsletterListOnSurface'],
    );

    return classes || undefined;
}

function NewsletterList({
    background,
    className,
    items,
}) {
    const classes = getClasses({ className, background });

    return (
        <div className={classes}>
            <Grid>
                <div className={styles['container']}>
                    <div className={styles['list']}>
                        {items.map(({ card_books, card_date, card_description, card_goals, card_income, card_link_url, card_title }, index) => {
                            const books = card_books;
                            const date = card_date;
                            const description = card_description;
                            const goals = card_goals;
                            const income = card_income;
                            const linkUrl = card_link_url;
                            const title = card_title?.text;

                            return (
                                <InView key={`newsletter-list-item-${index}`} threshold={0.1} triggerOnce={true}>
                                    {({ inView, ref, entry }) => {
                                        return (
                                            <div className={cx(styles['itemWrapper'], (inView) && styles['inview'])} ref={ref}>
                                                <div className={styles['item']}>
                                                    <HeadingBoundary>
                                                        <div className={styles['contentContainer']}>
                                                            <div className={styles['contentWrapper']}>
                                                                {date && (
                                                                    <Text as="span" className={styles['date']} emphasis="low" size={1}>{date}</Text>
                                                                )}
                                                                {title && (
                                                                    <Heading
                                                                        className={styles['title']}
                                                                        size={7}
                                                                    >
                                                                        <Link className={styles['link']} emphasis="high" to={linkUrl}>{title}</Link>
                                                                    </Heading>
                                                                )}
                                                                {description && (
                                                                    <Text as="p" className={styles['description']} emphasis="low" size={3}>{description}</Text>
                                                                )}
                                                            </div>
                                                            <ul className={styles['metaList']}>
                                                                <Text as="li" className={styles['metaItem']} emphasis="medium" size={2}>💰 £{income}k</Text>
                                                                <Text as="li" className={styles['metaItem']} emphasis="medium" size={2}>📙 {books}</Text>
                                                                <Text as="li" className={styles['metaItem']} emphasis="medium" size={2}>🥅 {goals}/3</Text>
                                                            </ul>
                                                        </div>
                                                    </HeadingBoundary>
                                                </div>
                                            </div>
                                        );
                                    }}
                                </InView>
                            );
                        })}
                    </div>
                </div>
            </Grid>
        </div>
    );
}

NewsletterList.propTypes = propTypes;
NewsletterList.defaultProps = defaultProps;

export { NewsletterList };
