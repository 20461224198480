import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import { Button } from 'components/Button';
import { Grid } from 'components/Grid';
import { Input } from 'components/Input';
import { Text } from 'components/Text';
import { cx } from 'utility';
import * as styles from './Newsletter.module.scss';

const defaultProps = {
    buttonText: 'Subscribe',
    className: '',
    description: '',
    emailLabel: 'Email',
    footerText: 'No spam, just great content every 4-5 weeks. Unsubscribe at any time.',
    nameLabel: 'First name',
    title: 'Newsletter',
    titleHidden: true,
};

const propTypes = {
    buttonText: PropTypes.string,
    className: PropTypes.string,
    description: PropTypes.string,
    emailLabel: PropTypes.string,
    footerText: PropTypes.string,
    nameLabel: PropTypes.string,
    title: PropTypes.string,
    titleHidden: PropTypes.bool,
};

function getClasses({ className, errorClassName, titleHidden, inView }) {
    const classes = cx(
        className,
        styles['newsletter'],
        styles[errorClassName],
        titleHidden && styles['titleHidden'],
        inView && styles['inview'],
    );

    return classes || undefined;
}

function Newsletter({
    buttonText,
    className,
    description,
    emailLabel,
    footerText,
    nameLabel,
    title,
    titleHidden,
}) {
    const [ref, inView, entry] = useInView({
        threshold: '0.2',
        triggerOnce: true,
    });
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState(null);
    const [errorClassName, setErrorClassName] = useState('');
    const classes = getClasses({ className, errorClassName, titleHidden, inView });

    function updateEmailValue(e) {
        setEmail(e.target.value);
    }

    function updateNameValue(e) {
        setName(e.target.value);
    }

    function resetErrorClassName() {
        setErrorClassName('');
    }

    async function submitForm(e) {
        e.preventDefault();

        const response = await addToMailchimp(email, {
            FNAME: name,
        });

        setErrorClassName(response.result);
        setMessage(response);
    }

    return (
        <>
            <div className={classes} id="newsletter" onAnimationEnd={resetErrorClassName} ref={ref}>
                <Grid background={true} className={styles['grid']} block={true} highlight={true}>
                    <Grid>
                        <div className={styles['container']}>
                            <form className={styles['form']} onSubmit={submitForm}>
                                <fieldset className={styles['fieldset']}>
                                    <Text as="legend" className={styles['title']} size={7}>{title}</Text>
                                    {description && (
                                        <Text as="p" className={styles['description']} size={3}>{description}</Text>
                                    )}
                                    {message && (
                                        <Text className={styles['message']} size={3}>{message.msg}</Text>
                                    )}
                                    <div className={styles['fields']}>
                                        <Input
                                            className={styles['input']}
                                            id="newsletter-name"
                                            label={nameLabel}
                                            name="name"
                                            onChange={updateNameValue}
                                            onSurface={true}
                                            required={true}
                                            placeholder={nameLabel}
                                            type="text"
                                        />
                                        <Input
                                            className={styles['input']}
                                            id="newsletter-email"
                                            label={emailLabel}
                                            name="email"
                                            onChange={updateEmailValue}
                                            onSurface={true}
                                            required={true}
                                            placeholder={emailLabel}
                                            type="email"
                                        />
                                        <Button className={styles['submitButton']} color="secondary" name="submit" type="submit">{buttonText}</Button>
                                    </div>
                                    <ul className={styles['footerList']}>
                                        <Text as="li" className={styles['footerItem']} size={1}><Text as="span" emphasis="high">📬</Text> Great content every 4-5 weeks (hopefully)</Text>
                                        {/* <Text as="li" className={styles['footerItem']} size={1}><Text as="span" emphasis="high">🌱</Text> 1 tree planted for every new subscriber</Text> */}
                                        <Text as="li" className={styles['footerItem']} size={1}><Text as="span" emphasis="high">🙈</Text> Unsubscribe at any time</Text>
                                    </ul>
                                </fieldset>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

Newsletter.propTypes = propTypes;
Newsletter.defaultProps = defaultProps;

export { Newsletter };
